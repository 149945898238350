<template>
  <BCard class="m-0 ">
    <div class="text-black text-2xl mb-2">
      {{ `${editAction ? 'Edit Skill' : 'Tambah Skill'}` }}
    </div>
    <ValidationObserver
      #default="{ invalid }"
      ref="formRules"
    >
      <BForm
        class="flex flex-col justify-between min-h-[70vh]"
        @submit.prevent="onFinish"
      >
        <BCol md="6">
          <ValidationProvider
            name="Nama Skill"
            rules="required"
          >
            <BFormGroup
              label="Nama Skill"
              label-cols-md="4"
              class="text-black"
            >
              <BFormInput
                v-model="name"
                placeholder="Nama skill baru"
              />
            </BFormGroup>
          </ValidationProvider>
          <BFormGroup
            label="Foto Icon"
            label-cols-md="4"
            class="text-black"
          >
            <BFormRow>
              <BCol>
                <ValidationProvider name="Icon Image">
                  <BFormFile
                    ref="fileInput"
                    v-model="icon"
                    accept="image/jpeg, image/png, image/gif"
                    :placeholder="iconInitial ?
                      iconInitial.split('/').pop()
                      : `Upload foto atau ikon`"
                    drop-placeholder="Upload foto atau ikon"
                    @change="uploadImage($event)"
                  />
                </ValidationProvider>
              </BCol>
            </BFormRow>
          </BFormGroup>
        </BCol>
        <div class="text-end">
          <BButton
            variant="outline-primary"
            class="mr-50"
            @click="$router.back()"
          >
            Batal
          </BButton>
          <BButton
            variant="primary"
            type="submit"
            :disabled="invalid || loading || disabled"
          >
            <BSpinner
              v-if="loading || loadingUpdate"
              small
            />
            Submit
          </BButton>
        </div>
      </BForm>
    </ValidationObserver>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import isEmpty from 'lodash/isEmpty'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      required,
      loading: false,
      loadingUpdate: false,
      error: '',
      name: '',
      icon: null,
      id: this.$route.params.id,
      iconInitial: null,
      isEmpty,
      disabled: false,
      alertError,
      alertSuccess,
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  mounted() {
    if (this.editAction) this.getSkilById()
  },
  methods: {
    async onFinish() {
      if (this.editAction) {
        this.onUpdate()
      } else {
        this.$refs.formRules.validate().then(async success => {
          if (success) {
            this.loading = true
            this.disabled = true
            const payload = new FormData()
            payload.append('name', this.name)
            payload.append('type', 2)
            if (this.icon !== null) { payload.append('icon', this.icon) }
            const url = '/v1/skills/store'

            await komtimAxiosIns.post(url, payload)
              .then(res => {
                const { meta } = res.data
                this.$router.push('/skill')
                this.loading = false
                this.disabled = true

                const text = 'Berhasil menambahkan data'
                this.alertSuccess(text)
              })
              .catch(err => {
                this.loading = false
                this.disabled = true

                this.alertError(err)
              })
          }
        })
      }
    },
    async getSkilById() {
      this.loading = true
      const url = `/v1/skills/${this.id}`
      await komtimAxiosIns.get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.name = data.name
          if (data.icon) this.iconInitial = data.icon
        })
    },
    async onUpdate() {
      this.disabled = true
      this.loadingUpdate = true
      const payload = new FormData()
      payload.append('name', this.name)
      payload.append('type', 2)
      if (this.icon !== null) { payload.append('icon', this.icon) }
      const url = `/v1/skills/${this.id}/update`

      await komtimAxiosIns.put(url, payload)
        .then(res => {
          const { meta } = res.data
          this.loadingUpdate = false
          this.disabled = true
          this.$router.push('/skill')

          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)
        })
        .catch(err => {
          this.loadingUpdate = false
          this.disabled = true
          this.alertError(err)
        })
    },
    uploadImage(e) {
      const icon = e.target.value
      const fileExtension = icon.substr((icon.lastIndexOf('.') + 1))
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png' && fileExtension !== 'gif') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF')
        this.icon = ''
      } else {
        this.icon = icon
      }
    },
  },
}
</script>
